import BusinessIcon from '@mui/icons-material/Business';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Divider, Paper, Stack, Typography } from '@mui/material';
import { Anchor, Paragraph } from '../../';
import { formatDate } from '../../../util';
import { IEmployer } from '../types';

export const Employer = ({
  employer,
  city,
  overview,
  description,
  end,
  position,
  start,
  url,
}: IEmployer) => {
  return (
    <Box component={Paper} p={3}>
      <Stack spacing={2}>
        <Typography variant="h3">{position}</Typography>

        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={1}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Stack direction="row" spacing={1}>
            <CalendarMonthIcon />{' '}
            <Typography>
              {formatDate(start)} - {end ? formatDate(end) : 'Present'}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <BusinessIcon /> <Typography>{employer}</Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <LocationOnIcon />
            <Typography>{city}</Typography>
          </Stack>
        </Stack>

        {overview && (
          <Box p={2} style={{ backgroundColor: '#eee', fontStyle: 'italic' }}>
            <Paragraph paragraphs={overview} disableMargin />
          </Box>
        )}

        <Paragraph paragraphs={description} />

        <Anchor href={url} target="_blank" rel="external" />
      </Stack>
    </Box>
  );
};
