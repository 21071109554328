import { Box, Container, Paper, Stack, Typography } from '@mui/material';
import {
  Education,
  Employer,
  Personal,
  Project,
  Skill,
} from '../core/cv/components';
import { data } from '../core/cv/data';
import { IEmployer, IProject, ISchool, ISkill } from '../core/cv/types';
import { sortByRange } from '../util';

const PageCV = () => {
  return (
    <Container>
      <Stack spacing={3} my={4}>
        <Personal {...data.personal} />

        {data.skills && data.skills.length > 0 && false && (
          <Box component={Paper} my={2} p={3}>
            <Stack direction="row" spacing={1}>
              {data.skills.map((skill: ISkill, index: number) => (
                <Skill key={index} {...skill} />
              ))}
            </Stack>
          </Box>
        )}

        {/* {data.social && data.social.length > 0 && (
            <Box component={Paper} my={2} p={2}>
              <Stack direction="row" spacing={1}>
                {data.social.map((social: ISocial, index: number) => (
                  <Social key={index} {...social} />
                ))}
              </Stack>
            </Box>
          )} */}

        <Stack spacing={3}>
          <Typography variant="h2">Projects</Typography>
          {/* <Alert severity="info">
            <Typography>
              Below are a few key projects I've worked on over the last 17+
              years
            </Typography>
          </Alert> */}
          {data.projects
            .sort(sortByRange)
            .reverse()
            .map((project: IProject, index: number) => (
              <Project key={index} {...project} />
            ))}
        </Stack>

        <Stack spacing={3}>
          <Typography variant="h2">Work Experience</Typography>
          {data.employment
            .sort(sortByRange)
            .reverse()
            .map((employer: IEmployer, index: number) => (
              <Employer key={index} {...employer} />
            ))}
        </Stack>

        <Stack spacing={3}>
          <Typography variant="h2">Education</Typography>
          {data.education
            .sort(sortByRange)
            .reverse()
            .map((school: ISchool, index: number) => (
              <Education key={index} {...school} />
            ))}
        </Stack>
      </Stack>
    </Container>
  );
};

export default PageCV;
