export const defaultThemeConfig = {
  typography: {
    fontFamily: 'Raleway, Arial',
    subtitle1: {
      fontSize: 12,
    },
    h1: {
      fontSize: '2.2rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.8rem',
    },
    h4: {
      fontSize: '1.6rem',
    },
    h5: {
      fontSize: '1.4rem',
    },
    h6: {
      fontSize: '1.2rem',
    },
    body1: {
      fontWeight: 500,
    },
    button: {
      fontStyle: 'italic',
    },
  },
};
