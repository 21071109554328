import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link, Stack } from '@mui/material';

/**
 *
 */
export interface LinkProps {
  /**
   * Specifies that the target will be downloaded when a user clicks on the hyperlink
   */
  download?: string;

  /**
   * Specifies the URL of the page the link goes to
   */
  href?: string;

  /**
   * Specifies the relationship between the current document and the linked document
   */
  rel?:
    | 'alternate'
    | 'author'
    | 'bookmark'
    | 'external'
    | 'help'
    | 'license'
    | 'next'
    | 'nofollow'
    | 'noreferrer'
    | 'noopener'
    | 'prev'
    | 'search'
    | 'tag';
  /**
   * Specifies where to open the linked document
   */
  target?: '_blank' | '_parent' | '_self' | '_top';

  /**
   *
   */
  onClick?: () => void;
}

interface Props extends LinkProps {
  /**
   *
   */
  label?: string;
}

/**
 *
 */
export const Anchor = ({ label, ...rest }: Props) => {
  // get a few values off the defaults
  const { href, rel } = rest;

  // if the user has set the type to be external, then we should consider implementing an icon
  const isExternal = rel === 'external';

  // if there is no href, then the anchor component tye should be a button
  const component = href ? 'a' : 'button';

  // the display text, if no label is supplied, fallback to the href
  const display =
    label ||
    (href
      ? href.replace(/^(https?:|)\/\/(www.)?/, '').replace(/\/$/, '')
      : href);

  if (!display) {
    return null;
  }

  return (
    <Link {...rest} component={component} variant="body1">
      <Stack component="span" spacing={1} direction="row">
        {isExternal && <OpenInNewIcon fontSize="small" />}
        <span>{display}</span>
      </Stack>
    </Link>
  );
};
