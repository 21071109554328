export interface DateRange {
  /**
   *
   */
  start: string;

  /**
   *
   */
  end: string;
}

/**
 *
 */
export const sortByRange = (a: DateRange, b: DateRange): number => {
  if (a.start < b.start) {
    return -1;
  }

  if (a.start > b.start) {
    return 1;
  }

  return 0;
};
