import { Box, Divider, Stack, Typography } from '@mui/material';
import React from 'react';

/**
 *
 */
type List = {
  [key: string]: React.ReactNode | string | number | boolean | null | undefined;
  // | {
  //     icon: React.ReactNode;
  //     label: React.ReactNode | string | number | boolean | null | undefined;
  //   };
};

interface Props {
  /**
   *
   */
  data: List;
}

/**
 *
 */
export const DefinitionList = ({ data }: Props) => {
  // convert the keys to an array that can be looped through
  const list = Object.keys(data);

  return (
    <Stack
      component="dl"
      spacing={1}
      direction={{ xs: 'column', md: 'row' }}
      justifyContent="space-between"
      divider={<Divider orientation="vertical" flexItem />}
    >
      {list.map((key: any, index: number) => {
        //
        const value = data[key];
        const icon = false; // <SchoolIcon fontSize="large" />;

        return (
          <Stack
            direction="row"
            // alignItems="center"
            spacing={icon ? 2 : 0}
            key={`item-${index}`}
          >
            {icon}
            <Box>
              <Typography component="dt" fontWeight={600}>
                {key}
              </Typography>
              <Typography component="dd">{value}</Typography>
            </Box>
          </Stack>
        );
      })}
    </Stack>
  );
};
