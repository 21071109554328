import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SchoolIcon from '@mui/icons-material/School';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { Box, Divider, Paper, Stack, Typography } from '@mui/material';
import { Paragraph } from '../../';
import { formatDate } from '../../../util';
import { ISchool } from '../types';

export const Education = ({
  school,
  degree,
  city,
  description,
  end,
  field,
  grade,
  start,
}: ISchool) => {
  return (
    <Box component={Paper} p={3}>
      <Stack spacing={2}>
        <Typography variant="h3">{school}</Typography>

        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={1}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Stack direction="row" spacing={1}>
            <CalendarMonthIcon />{' '}
            <Typography>
              {formatDate(start)} - {end ? formatDate(end) : 'Present'}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <SchoolIcon />{' '}
            <Typography>
              {degree} - {field}
            </Typography>
          </Stack>

          {grade && (
            <Stack direction="row" spacing={1}>
              <WorkspacePremiumIcon /> <Typography>{grade}</Typography>
            </Stack>
          )}

          <Stack direction="row" spacing={1}>
            <LocationOnIcon />
            <Typography>{city}</Typography>
          </Stack>
        </Stack>

        <Paragraph paragraphs={description} disableMargin />
      </Stack>
    </Box>
  );
};
