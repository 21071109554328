import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, Chip, Divider, Paper, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Anchor, Paragraph } from '../../';
import { formatDate, isEmpty } from '../../../util';
import { IProject } from '../types';

export const Project = ({
  active = true,
  title,
  logo,
  start,
  end,
  role,
  url,
  overview,
  description,
  tags,
  links,
}: IProject) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));

  if (!active || isEmpty(description)) {
    return null;
  }

  return (
    <Box component={Paper} p={3}>
      <Stack spacing={2}>
        <Stack direction="row" spacing={3} alignItems="center">
          {logo && (
            <Box component={Paper} p={1} ml={matches ? -10 : 0}>
              <img
                src={logo}
                width={100}
                height={100}
                style={{ display: 'block' }}
              />
            </Box>
          )}

          <Box>
            <Typography variant="h3">{title}</Typography>
            <Typography component="b" fontWeight={600}>
              {role}
            </Typography>
          </Box>
        </Stack>

        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={1}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Stack direction="row" spacing={1}>
            <CalendarMonthIcon />{' '}
            <Typography>
              {formatDate(start)} - {end ? formatDate(end) : 'Present'}
            </Typography>
          </Stack>
        </Stack>

        {overview && (
          <Box p={2} style={{ backgroundColor: '#eee', fontStyle: 'italic' }}>
            <Paragraph paragraphs={overview} disableMargin />
          </Box>
        )}
        <Paragraph paragraphs={description} disableMargin />

        <Anchor href={url} target="_blank" rel="external" />

        {links && links.length > 0 && (
          <Box>
            <Typography variant="h5">Links</Typography>
            {links.map(({ label, url }, index: number) => (
              <Box key={`link-${index}`}>
                <Anchor href={url} label={label} target="_blank" />
              </Box>
            ))}
          </Box>
        )}

        {false && tags && tags.length > 0 && (
          <Stack direction="row" spacing={1}>
            {tags.sort().map((tag, index: number) => (
              <Chip label={tag} key={`tag-${index}`} variant="outlined" />
            ))}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
