import { Avatar, Box, Divider, Paper, Stack, Typography } from '@mui/material';
import { Anchor, DefinitionList, Paragraph } from '../../';
import { replaceKeysInString } from '../../../util';
import { IPersonal } from '../types';

export const Personal = ({
  address,
  title,
  intro,
  avatar,
  email,
  firstname,
  lastname,
  phone,
  website,
  summary,
}: IPersonal) => {
  return (
    <>
      <Box component={Paper} my={2} p={3}>
        <Stack
          spacing={3}
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Box>
            <Avatar
              src={avatar}
              alt={`${firstname} ${lastname}`}
              sx={{ width: 300, height: 300 }}
            />
          </Box>

          <Stack spacing={2}>
            {intro && (
              <Typography variant="h4" component="p">
                {replaceKeysInString(intro, { firstname })}
              </Typography>
            )}
            <Paragraph paragraphs={summary} disableMargin />

            <DefinitionList
              data={{
                // Address: address,
                // Phone: phone,
                Email: (
                  <Anchor
                    href={`mailto:${email}`}
                    label={email}
                    target="_blank"
                  />
                ),
                Website: (
                  <Anchor
                    href={website}
                    label="michaelord.me"
                    target="_blank"
                  />
                ),
                LinkedIn: (
                  <Anchor
                    href="https://linkedin.com/in/michaelord"
                    label="linkedin.com/in/michaelord"
                    target="_blank"
                  />
                ),
              }}
            />
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
