import { replaceKeysInString } from '../../util';
import {
  IEmployer,
  IPersonal,
  IProject,
  ISchool,
  ISkill,
  ISocial,
} from './types';

interface ICV {
  personal: IPersonal;
  employment: IEmployer[];
  education: ISchool[];
  social: ISocial[];
  skills: ISkill[];
  projects: IProject[];
}

// get the current year
const years = new Date().getFullYear() - 2005;

export const data: ICV = {
  personal: {
    address: '94 Roseden Way, Great Park, Newcastle upon Tyne, NE13 9DS',
    title: 'Software Engineer',
    avatar: '/media/profile.jpg',
    firstname: 'Michael',
    lastname: 'Ord',
    email: 'michael.ord@gmail.com',
    website: 'https://michaelord.me',
    phone: '+44 (0)7875 769 354',
    intro: `Hi, I'm {firstname}, a Software Engineer`,
    summary: [
      replaceKeysInString(
        ` I have {years}+ years of commercial experience working on various projects in varying sectors, in small and large teams.`,
        { years }
      ),

      `I pride myself on being a hard-working developer with a flair for creating elegant solutions in the least amount of time.
      I'm always looking to maximise productivity and reduce maintenance through components or automation; this ultimately benefits the project, the team and the end customer. In addition, should a task need to be repeated, there's a good chance you can automate it through tooling.`,

      `I am an advocate for templating to speed up front-end development and allow new team members to be onboarded with minimal loss of time. Maintaining those templates throughout the lifespan of a project provides a single source of truth about what to expect in the live environment.`,
    ],
  },

  employment: [
    {
      employer: 'TH_NK',
      url: 'http://wearethink.com',
      city: 'Newcastle upon Tyne, UK',
      position: 'Developer',
      start: '2005-06',
      end: '2012-06',
      overview: [
        `TH_NK is a digital transformation agency. We work with clients like Vue, Warner Bros., Butlins, Nando's and Atom Bank to set and realise their digital ambitions.`,

        `Ten years has taught us that the need for digital transformation comes in waves. When in a perfect storm, organisations are no longer aligned to a common goal, customers' expectations outpace their experience, and technology becomes a constraint rather than an enabler.`,

        `We uniquely blend the skills and talent from the three kinds of partner clients typically need today. We combine the business focus of consultancies, the customer centricity of agencies, and the tech capability of systems integrators. We believe this makes us a partner for change.`,
      ],
      description: [
        `I joined TH_NK after attending an open day hosted by them. Initially interviewed for a design role, it was apparent that my more code-oriented work suited better to a developer role, joining initially on a single-month contract. This role was made permanent after two weeks of proving myself.`,
      ],
    },
    {
      employer: 'TH_NK',
      url: 'http://wearethink.com',
      city: 'Newcastle upon Tyne, UK',
      position: 'Senior Developer',
      start: '2012-06',
      end: '2020-01',
      overview: [
        `TH_NK is a digital transformation agency. We work with clients like Vue, Warner Bros., Butlins, Nando's and Atom Bank to set and realise their digital ambitions.`,

        `Ten years has taught us that the need for digital transformation comes in waves. When in a perfect storm, organisations are no longer aligned to a common goal, customers' expectations outpace their experience, and technology becomes a constraint rather than an enabler.`,

        `We uniquely blend the skills and talent from the three kinds of partner clients typically need today. We combine the business focus of consultancies, the customer centricity of agencies, and the tech capability of systems integrators. We believe this makes us a partner for change.`,
      ],
      description: [
        `I was promoted to Senior Developer; during this role, I pushed the development team to adopt new modern approaches to front-end development. One of which now seems trivial was the move from CSS to less/stylus/scss. There were pushbacks from the team in general; however, after implementing this on a critical project, the benefits, time savings and maintainability were clear. Soon after, as a company, we moved to pre-processors and nodejs for building sites as a standard.`,
      ],
    },
    {
      employer: 'EPAM',
      url: 'http://epam.com',
      city: 'Newcastle upon Tyne, UK',
      position: 'Senior Software Engineer',
      start: '2020-01',
      end: '',
      overview: [
        `EPAM Systems, Inc. is an American company that specialises in service development, digital platform engineering, and digital product design. One of the world's largest manufacturers of custom software and consulting providers.`,
      ],
      description: [
        `I joined the EPAM family through their acquisition of TH_NK.`,
      ],
    },
  ],
  education: [
    {
      school: 'Bedlingtonshire High School',
      degree: 'A-Levels',
      field: 'Mathematics, Art, Design Technology',
      grade: '',
      start: '1998-09',
      end: '2000-07',
      city: 'Northumberland, UK',
      description: '',
    },
    {
      school: 'Newcastle College',
      degree: 'HND',
      field: 'Multimedia Design',
      grade: 'Distinction',
      start: '2001-09',
      end: '2002-06',
      city: 'Newcastle upon Tyne, UK',
      description: [
        `I came into this course with a self-taught coding background. I understood the basics of CSS and HTML and had a solid foundation in ActionScript 3 through writing games in my spare time. This knowledge allowed me to excel in this class. In addition, my advanced knowledge enabled me to help tutors and students with work.`,

        `During this course, I took on additional work from tutors to help them with their side projects.`,
      ],
    },
    {
      school: 'Northumbria University',
      degree: 'BAHons',
      field: 'Multimedia Design',
      grade: '1st Class',
      start: '2002-09',
      end: '2005-06',
      city: 'Newcastle upon Tyne, UK',
      description: [
        `The distinction gained from Newcastle College allowed me to join the third year of this course; this was a significant shift in pace and required dedication to keep up with the demands.`,

        `Out of a class of 30 students, I was only one of two students to pass with first-class honours.`,
      ],
    },
  ],
  social: [
    {
      label: 'LinkedIn',
      url: 'https://www.linkedin.com/in/michaelord/',
    },
  ],
  skills: [
    {
      label: 'React',
      level: 5,
    },
    {
      label: 'JavaScript',
      level: 5,
    },
    {
      label: 'Typescript',
      level: 5,
    },
    {
      label: 'es6',
      level: 5,
    },
    {
      label: 'jQuery',
      level: 5,
    },
    {
      label: 'HTML',
      level: 5,
    },
    {
      label: 'CSS',
      level: 5,
    },
    {
      label: 'SCSS/SASS',
      level: 5,
    },
    {
      label: 'material-ui',
      level: 5,
    },
    {
      label: 'nodejs',
      level: 5,
    },
    {
      label: 'mobile first',
      level: 5,
    },
  ],
  projects: [
    {
      title: 'Pottermore',
      logo: '/media/logo-pottermore.png',
      start: '2011-01',
      end: '2012-01',
      role: 'Developer',
      url: '',
      overview: [
        `Pottermore was an online Harry Potter website created to offer fans of the literature a new and exciting way to interact with the world and characters of Harry Potter.`,
      ],
      description: [
        `The Pottermore project spanned 4+ years, consisting of a digital offering of all the Harry Potter books, working within a large team to deliver interactive chapters of each book, game, and leaderboards.`,

        `My primary role in this project was developing reusable templates and interactions for each of the chapters of each book (198 in total). Initially written in Actionscript 3.`,

        `Worked closely with a Visual Agency to develop over 50 immersive scenes (moments) and bring them to life using Flash. Provided a set of templates and documentation for a third-party company to follow.`,

        `We later switched and decided to re-write it in HTML5 to offer support to iOS devices. To aid this switch, I developed automated scripts to repurpose assets and manifests to make the transition quicker, smoother and with minimal errors.`,

        `Worked closely with Freelance Flash game developers to help integrate with backend API Creating a top-rated site which provided joy to many harry potter fans.`,
      ],
      tags: ['sitecore', 'jquery', 'actionscript', 'canvas', 'scss'],
      links: [
        {
          label: '',
          url: 'https://www.youtube.com/watch?v=wzSRR6Cb1og',
        },
        {
          label: '',
          url: 'https://www.youtube.com/watch?v=UBceJSHClds',
        },
        {
          label: '',
          url: 'https://www.youtube.com/watch?v=eT88vkqfgzA',
        },
      ],
    },
    {
      title: 'GRIP SharePoint Online Intranet',
      logo: '/media/logo-fletcher.png',
      start: '2013-10',
      end: '2014-04',
      role: 'Developer',
      url: '',
      overview: '',
      description: [
        `The delivery of Fletcher Building's Group SharePoint Online Intranet, in use in over five continents by 40+ business units with 22,000+ users.`,
        `This project involved working directly within the Sharepoint application, configuring settings and overriding styles to theme the interface to the client requirements and brand.`,
      ],
      tags: ['css', 'javascript'],
    },
    {
      title: 'Vue',
      logo: '',
      start: '2017-12',
      end: '2018-02',
      role: 'Developer',
      url: 'https://www.myvue.com/',
      overview: '',
      description: [``],
      tags: ['scss', 'javascript', 'sitecore'],
    },
    {
      title: 'Northumbrian Water',
      logo: '',
      start: '2012-05',
      end: '2012-08',
      role: 'Developer',
      url: 'https://www.nwl.co.uk/',
      overview: '',
      description: [``],
      tags: ['css', 'javascript'],
    },
    {
      title: 'Arup Careers',
      logo: '/media/logo-arup.png',
      start: '2020-01',
      end: '2020-04',
      role: 'Lead Developer',
      url: '',
      overview: '',
      description: [
        `Arup Careers was a third-party portal that required the creation of styles to match the branding of the leading corporate site. Initially, styling proved difficult as the only way to implement them was through a text field. I overcame this obstacle by implementing a proxy service allowing me to run a site locally, scraping the live HTML, JavaScript and base styles and injecting the new overriding styles; this allowed for a much faster delivery than expected, which increased the profit margin.`,
        `By working closely with the client and designer to help define what we could achieve within the limitations of the portal.`,

        `I Redfined the backlog and developed a relationship with the client with regular feedback and demo sessions.`,
      ],
      tags: ['scss', 'nodejs', 'javascript'],
    },
    {
      active: false,
      title: 'Arup',
      logo: '/media/logo-arup.png',
      start: '2019-06',
      end: '2019-08',
      role: '',
      url: '',
      overview: [``],
      description: [
        `Delivered a series of page templates and components to the backend development team to implement. Written in handlebars, components were appropriately split up to maximise reuse. They also were used to help guide how the data could be structured within Sitecore.`,
      ],
      tags: ['handlebars'],
    },
    {
      title: 'Badenoch + Clark',
      logo: '/media/logo-b+c.png',
      start: '2018-02',
      end: '2019-03',
      role: 'Lead Developer',
      url: 'https://www.badenochandclark.com/',
      overview: '',
      description: [
        `Working closely with design and UX, I designed and built a suite of components. These components formed a set of page templates to be handed over to the backend developers to implement within Sitecore. The naming and hierarchy enabled the backend developers to model their components. In addition, a BEM approach was taken for styling, which was especially useful as these templates were used to form the base for a few future Adecco projects.`,
      ],
      tags: ['scss', 'jquery', 'handlebars', 'sitecore'],
    },
    {
      title: 'abrdn',
      logo: '/media/logo-abrdn.png',
      start: '2021-02',
      end: '',
      role: 'Contract Developer',
      url: 'https://connect.abrdn.com/',
      overview: [
        `abrdn plc, formerly Standard Life Aberdeen plc, is a United Kingdom-based global investment company headquartered in Edinburgh, Scotland. It is listed on the London Stock Exchange and is a constituent of the FTSE 100 Index.`,
      ],
      description: [
        `Subcontracted through EPAM, I have integrated into the abrdn development team, working closely with other third-party providers to deliver a client portal to service abrdn clients.`,
      ],
      tags: ['reactjs', 'material-ui', 'webpack', 'typescript'],
    },
    {
      title: 'Focus Solutions',
      logo: '/media/logo-focus-solutions.png',
      start: '2020-10',
      end: '2021-02',
      role: 'Contract Developer',
      url: '',
      overview: ``,
      description: [
        `Working closely with the Focus Solutions team, I delivered a digital toolkit to help build financial wellness; this required integrations with an API and other third-party services.`,

        `Written in handlebars, jQuery and bootstrap, I moved the application to a SPA React application which is part of a larger piece of work within the company to modernise their technology stack and align the products offered to customers.`,
      ],
      tags: ['css', 'scss', 'jquery', 'material-ui', 'react'],
    },
    {
      title: 'LV=',
      logo: '/media/logo-lv.png',
      start: '2018-09',
      end: '2019-01',
      role: 'Developer',
      url: 'https://www.lv.com/',
      overview: [
        `Liverpool Victoria (financial services) - is one of the UK's largest insurance companies, with over five million customers offering a range of insurance and retirement products.`,
      ],
      description: [
        `Design & build the www.lv.com website & over ten microsites using Sitecore as a CMS. The project was a new shift in technical direction for the company defining a future use of Sitecore and React technologies. There was also a critical focus around site performance where stringent NFRs were successfully delivered, becoming a Google case study.`,

        `Partnered with a colleague to provide 90+ components for reuse within Sitecore; the components required special consideration as the content also needed to be easily edited in editor mode.`,

        `Regularly discussed and adapted our build approach as we learned and developed our ReactJS understanding due to this being our first dive into the world of React.`,
      ],
      tags: ['reactjs', 'sitecore', 'webpack', 'typescript'],
    },
    {
      title: 'Formica',
      logo: '/media/logo-formica.png',
      start: '2013-05',
      end: '2013-08',
      role: 'Lead Developer',
      url: 'https://www.formica.com/',
      overview: '',
      description: [
        `Redesign and build the Formica Group, North America website using mobile-first responsive principles.`,

        `Initially brought into this project to help speed up delivery for the client. However, after the initial inspection, I discovered that the wrong approach had been taken. Rather than the standard mobile-first build, the original developer had taken desktop-first, which was decided would be a maintenance issue. This was raised to all relevant parties, who decided to delay the delivery to give the customer the best product possible. Over the next six weeks, I implemented .less stylesheets (relatively new at the time), re-wrote the entire CSS and fine-tuned the markup.`,
      ],
      tags: ['handlebars', 'jquery', 'less'],
    },
    {
      title: 'Utopia - Channel 4',
      logo: '',
      start: '2012-09',
      end: '2012-11',
      role: 'Developer',
      url: '',
      overview: [
        `Utopia is a British thriller drama television series that was broadcast on Channel 4`,
      ],
      description: [``],
      tags: ['actionscript', 'jquery', 'css', 'flash'],
    },
    {
      title: 'Warner Bros Studio Tours',
      logo: '',
      start: '2018-01',
      end: '2018-12',
      role: 'Developer',
      url: 'https://www.wbstudiotour.co.uk/',
      overview: '',
      description: [``],
      tags: ['wordpress', 'scss', 'javascript', 'jquery', 'handlebars'],
    },
    {
      title: 'Nandos',
      logo: '/media/logo-nandos.png',
      start: '2014-02',
      end: '2014-08',
      role: 'Developer',
      url: 'https://www.nandos.com.au/',
      overview: [
        `Nando's is a South African multinational fast-casual chain that specialises in flame-grilled peri-peri style chicken. Founded in Johannesburg in 1987, Nando's operates over 1,200 outlets in 30 countries.`,
      ],
      description: [
        `Undertook the complete front-end build of the Nandos Australia site.`,

        `Responsible for delivering a set of very image-heavy templates that needed to work mobile-first whilst keeping true to the Nandos branded menus and style. This was a feat in itself, requiring hundreds of images to be sliced, diced and repeated to save on bandwidth.`,
      ],
      tags: ['handlebars', 'drupal', 'scss'],
    },
    {
      title: 'Very',
      logo: '/media/logo-very.png',
      start: '2016-07',
      end: '2016-11',
      role: 'Developer',
      url: 'https://www.very.co.uk/',
      overview: [
        `Very is a British online retailer with headquarters in Speke, Liverpool. The brand was launched in the UK in July 2009 as part of the Shop Direct Group Very had formerly been known as Littlewoods Direct and formerly Woolworths.co.uk.`,
      ],
      description: [
        `Working in a small team, we created templates, components and utility classes that clients could use to build new pieces.`,

        `I was brought on to the team to help with the fast-paced sprints and iterations that the client required. I Implemented a dynamic templating system that allowed for the reuse of code snippets rather than maintaining several templates containing the same code - resulting in faster development—We implemented mobile and desktop versions of templates—provided over 150-page templates for the client to implement.`,
      ],
      tags: ['handlebars', 'scss'],
    },
    {
      title: 'TV Licencing',
      logo: '/media/logo-tvl.png',
      start: '2009-01',
      end: '2011-01',
      role: 'Developer',
      url: 'https://www.tvlicensing.co.uk/',
      overview: [
        `TV Licencing - Payment and brochure-ware platform for UK TV Licensing.`,
      ],
      description: [
        `Front-End developer on the UK's TV Licencing platform. Worked within the front-end development team producing HTML, CSS and JS solutions for the UI Integration of UI assets with .NET and Reddot CMS platform.`,

        `The TV licencing website was built 10+ years ago. The site is still alive; while visually, now it looks very dated compared to today's standards, it has stood the test of time and still fulfils its purpose.`,
      ],
      tags: ['reddot', 'css', 'jquery'],
    },
    {
      title: 'Adecco Amazon',
      logo: '/media/logo-adecco.png',
      start: '2019-04',
      end: '2019-08',
      role: 'Lead Developer',
      url: 'https://www.adecco-jobs.com/amazon/',
      overview: '',
      description: [
        `I created a series of reusable components and worked closely with UX to define the minimum details necessary to deliver within a tight timescale while providing flexibility to the client.`,

        `Worked closely with the PM, BA and UX lead to ensure the proposed solution was technically and realistically feasible within the given timeline.`,

        `Responsible for code quality auditing.`,
      ],
      tags: ['sitecore', 'scss', 'javascript'],
    },
    {
      active: false,
      title: 'Playstation',
      start: '2011-01',
      end: '2011-08',
      role: 'Developer',
      url: '',
      overview: '',
      description: [``],
      tags: [],
    },
    {
      title: 'Atom Bank',
      logo: '/media/logo-atom.png',
      start: '2015-01',
      end: '2017-01',
      role: 'Developer',
      url: 'https://www.atombank.co.uk/',
      overview: [
        `Atom Bank is a retail bank in the United Kingdom. The United Kingdom's first bank built for smartphones or tablets, without any branches, and the first digital-only challenger bank to be granted a full UK regulatory licence.`,
      ],
      description: [
        `Working as part of a larger team, tasked with creating animations and interactivity for the homepage to help sell their new banking proposition.`,
      ],
      tags: ['html5', 'canvas', 'jquery', 'scss'],
    },
    {
      title: 'Northern Rock',
      logo: '/media/logo-northern-rock.png',
      start: '2005-01',
      end: '2006-01',
      role: 'Developer',
      url: '',
      overview: [
        `Northern Rock, formerly the Northern Rock Building Society, was a British bank. Based at Regent Centre in Newcastle upon Tyne, United Kingdom, Northern Rock was originally a building society. It demutualised and became Northern Rock bank in 1997 when it floated on the London Stock Exchange with the ticker symbol NRK.`,
      ],
      description: [
        `The first project I worked on after joining TH_NK.`,

        `Maintenance for Northern Rock was a daily occurrence, with multiple daily updates. This involved working to client specs with a rapid response time. As part of the maintenance team, I was responsible for updates and releases to staging and live environments.`,
      ],
      tags: ['css', 'javascript'],
    },
    {
      title: 'Lee Hecht Harrison',
      logo: '/media/logo-lhh.png',
      start: '2017-02',
      end: '2017-08',
      role: 'Lead Developer',
      url: 'https://www.lhh.com/',
      overview: [`Recruitment Portal for a branch of Adecco.`],
      description: [
        `I delivered a set of concise templates.`,

        `Created a JS library allowing us to move away from the 'bloat' of jQuery.`,
        `Defined a template system using dotjs and compiled through Node to enable fast development and testing before work was handed to a backend team to implement. Worked closely with design and UX to define a set of 70+ functional components.`,
      ],
      tags: ['dotjs', 'scss', 'javascript'],
    },
  ],
};
