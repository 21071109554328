import { Typography } from '@mui/material';
import React from 'react';

interface Props {
  /**
   * Flag to disable the margin on the last paragraph rendered
   */
  disableMargin?: boolean;

  /**
   * The list of paragraphs to output
   */
  paragraphs: string | string[];
}

/**
 *
 */
export const Paragraph = ({ paragraphs, disableMargin = false }: Props) => {
  if (!paragraphs) {
    return null;
  }

  const copy = typeof paragraphs === 'string' ? [paragraphs] : paragraphs;

  return (
    <>
      {copy.map((paragraph, index: number) => (
        <Typography
          paragraph={!(disableMargin && index === copy.length - 1)}
          key={`p-${index}`}
        >
          {paragraph}
        </Typography>
      ))}
    </>
  );
};
