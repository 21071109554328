/**
 *
 */
export const isObjectEmpty = (obj: unknown): boolean => {
  if (typeof obj !== 'object') {
    return false;
  }

  return !!(
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

/**
 *
 */
export const isArray = (obj: unknown): boolean => {
  return obj instanceof Array;
};

/**
 *
 */
export const isEmpty = (obj: unknown): boolean => {
  try {
    if (isArray(obj)) {
      return (obj as Array<string>).join('') === '';
    }

    if (typeof obj === 'string') {
      return obj === '';
    }

    if (typeof obj !== 'object') {
      return isObjectEmpty(obj);
    }
  } catch (e) {
    return false;
  }

  return false;
};
