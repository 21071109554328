import { createTheme, ThemeProvider } from '@mui/material';
import { defaultThemeConfig } from './defaultThemeConfig';

interface Props {
  /**
   *
   */
  children: React.ReactNode;
}

// generate the material theme
const theme = createTheme(defaultThemeConfig);

/**
 *
 * @returns
 */
export const DefaultTheme = ({ children }: Props) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
