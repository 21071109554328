import React from 'react';
// import { CookiePolicy } from './core';
import PageCV from './pages/PageCV';
// import { Footer, Header } from './partials';
import { Theme } from './styles';

function App() {
  return (
    <React.StrictMode>
      <Theme.Default>
        {/* <Header /> */}
        <PageCV />
        {/* <Footer /> */}
        {/* <CookiePolicy /> */}
      </Theme.Default>
    </React.StrictMode>
  );
}

export default App;
